import React from "react";
import { useSelector } from "react-redux";
import { selectTimer } from "../store/services/timer";
import "./Timer.css";
//Timer.js

export const Timer = () => {
  let dangerState = "";
  const time = useSelector(selectTimer);

  if (time < 15) {
    dangerState = "timer-warning";
  }

  if (time < 6) {
    dangerState = "timer-danger";
  }

  return (
    <div className="timer-container">
      <div className="timer-clockBody" />
      <div className="timer-clockHand" />
      <div className={"timer-content textOutline " + dangerState}>
        {Math.max(time, 0)}
      </div>
    </div>
  );
};
