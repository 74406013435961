import { createSlice } from "@reduxjs/toolkit";
import { generateStartingNickname } from "../../generateStartingNickname/generateStartingNickname";

const initialState = {
  name: generateStartingNickname(),
  isDirty: false,
};

export const nicknameSlice = createSlice({
  name: "nickname",
  initialState,
  reducers: {
    setInitialNickname: (state, action) => {
      state.name = action.payload;
    },
    setNickname: (state, action) => {
      state.name = action.payload;
      state.isDirty = true;
    },
  },
});

// export selector
export const selectNickname = (state) => state.nickname.name;
export const selectIsNicknameDirty = (state) => state.nickname.isDirty;

// Action creators are generated for each case reducer function
export const { setNickname, setInitialNickname } = nicknameSlice.actions;

export default nicknameSlice.reducer;
