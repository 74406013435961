import * as _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setName } from "../../io/api";
import {
  selectIsNicknameDirty,
  selectNickname,
  setNickname,
} from "../../store/services/nickname";
import "./Home.css";
import { Join } from "./Join";

//home.js
// props
//	onJoin
// 	onCreate

const NICKNAME_CHAR_LIMIT = 30;

export const Home = ({ joinComponent }) => {
  const nameInput = useRef(null);
  const nickname = useSelector(selectNickname);
  const isNicknameDirty = useSelector(selectIsNicknameDirty);
  const dispatch = useDispatch();

  const processNewNickname = (newNickname) => {
    if (newNickname.length > NICKNAME_CHAR_LIMIT) {
      newNickname = newNickname.substr(0, NICKNAME_CHAR_LIMIT);
    }
    return newNickname;
  };

  const handleSetServerName = (name) => {
    const newNickname = processNewNickname(
      name ? name : nameInput.current.value
    );
    setName(newNickname);
  };

  const throttledSetServerName = _.throttle(handleSetServerName, 1000);

  useEffect(() => {
    handleSetServerName(nickname);
  }, []);

  const handleNameChange = (e) => {
    e.preventDefault();

    let nickname = e.target.value;
    processNewNickname(nickname);
    dispatch(setNickname(nickname));
    throttledSetServerName();
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Welcome!</h3>
          <p className="card-text">
            The Wiki How game is a crazy guess-the-title party. Emphasis on the
            party.
          </p>
          <p className="card-text">
            This game is best experienced with 4+ players who can hear each
            other. If you aren't in the same room, hop on a call! If that's not
            possible, you can make use of the chat feature by clicking the
            bubble icon in the top right.
          </p>
          <p className="card-text"></p>
        </div>
      </div>
      <div className="card-space" />
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Play</h3>
          <div className="end-space">
            <label>Choose a nickname:</label>
            <br />
            <input
              onChange={handleNameChange}
              {...(!isNicknameDirty ? { placeholder: nickname } : {})}
              {...(isNicknameDirty ? { value: nickname } : {})}
              className="home-nameInput"
              ref={(el) => (nameInput.current = el)}
            />
            {nickname.length === NICKNAME_CHAR_LIMIT ? (
              <p className="home-nameInput-helperText">
                your nickname can only be {NICKNAME_CHAR_LIMIT} characters{" "}
                <i className="fas fa-thumbs-up"></i>
              </p>
            ) : null}
          </div>
          <Join />
        </div>
      </div>
    </div>
  );
};
