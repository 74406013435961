import adjectives from "./adjectives.json";
import nouns from "./nouns.json";

// data from https://github.com/C0urante/avro-random-generator

// https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
function capitalizeFirstLetter(string) {
  return string?.length ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const generateStartingNickname = () => {
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const noun = nouns[Math.floor(Math.random() * nouns.length)];

  return `${capitalizeFirstLetter(adjective)}${capitalizeFirstLetter(
    noun
  )}${getRandomArbitrary(1000, 9999)}`;
};
