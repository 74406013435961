import React, { useRef, useState } from "react"; //create.js
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendCreate } from "../../io/api";
import { gameStateEnum } from "../../io/apiEnums";
import { ROUTES } from "../../router";
import { setGameState } from "../../store/services/gameState";
// onCreate

//create.js
// onCreate

export const Create = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [issues, setIssues] = useState([]);
  const [password, setPassword] = useState(false);

  const _code = useRef(undefined);

  const _private = useRef(undefined);

  const _endScore = useRef(undefined);

  const _password = useRef(undefined);

  const getCode = () => {
    return _code.current.value || "default game name";
  };

  const getEndScore = () => {
    // TODO verify that the value is valid..
    return _endScore.current.value;
  };

  const getPublic = () => {
    let output = _private.current ? !_private.current.checked : true;
    console.log("public: " + output);
    return output;
  };

  const getPassword = () => {
    return _password.current ? _password.current.value : "";
  };

  const handlePasswordCheckClick = () => {
    setPassword(!password);
  };

  const handleNumberInput = () => {
    // input must be a positive integer.
    input.value = input.value.replace(/[^1234567890]/g, "");
  };

  const handleCreate = (e) => {
    e.preventDefault(); //should verify that the data is valid here as well.

    let issues = [];
    let game = {
      code: null,
      endScore: null,
      public: null,
      password: null,
    };

    try {
      game.code = getCode();
    } catch (e) {
      issues.push(e);
    }

    try {
      game.endScore = getEndScore();
    } catch (e) {
      issues.push(e);
    }

    try {
      game.public = getPublic();
    } catch (e) {
      issues.push(e);
    }

    try {
      game.password = getPassword();
    } catch (e) {
      issues.push(e);
    }

    if (issues.length === 0) {
      // don't need to clear issues as the component is about to be destroyed by moving to a new screen.
      // test whether this sticks around.
      dispatch(setGameState(gameStateEnum.waitCreate));

      sendCreate(game);
      navigate(ROUTES.root + ROUTES.game);
    } else {
      // @kevin 2024/2/4: what are we supposed to do with this class state
      // issues? Currently we don't do anything with them.
      setIssues(issues.map((e) => e.message));
    }
  };

  const renderPasswordInput = () => {
    if (password) {
      return (
        <React.Fragment>
          <div className="card-space" />
          <div className="row">
            <div className="col">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">Password:</span>
                </div>
                <input
                  className="form-control"
                  ref={(x) => (_password.current = x)}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return <div />;
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Create a game</h5>
          <form onSubmit={handleCreate}>
            <div className="row">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Game name:</span>
                  </div>
                  <input
                    className="form-control"
                    ref={(x) => (_code.current = x)}
                  />
                </div>
              </div>
            </div>
            <div className="card-space" />
            <div className="row">
              <div className="col">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Winning Score:</span>
                  </div>
                  <input
                    className="form-control"
                    ref={(x) => (_endScore.current = x)}
                    onChange={() => handleNumberInput(_endScore.current)}
                    defaultValue="20"
                  />
                </div>
              </div>
            </div>
            <div className="card-space" />
            <div className="row">
              <div className="col">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    ref={(x) => (_private.current = x)}
                  />
                  <label className="form-check-label"> game is private </label>
                </div>
              </div>
            </div>
            <div className="card-space" />
            <div className="row">
              <div className="col">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={handlePasswordCheckClick}
                    value={password}
                  />
                  <label className="form-check-label"> require password </label>
                </div>
              </div>
            </div>
            {renderPasswordInput()}
            <div className="card-space" />
            <div className="row">
              <div className="col">
                <button className="btn btn-success">Create</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card-space" />
    </div>
  );
};
