import React from "react";

import { useSelector } from "react-redux";
import { selectGame } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";
/**
	STEP 3

 * This will show the votes and how many points they made along with who made the guess
    will display if the vote were the real one as well.
	
	will also show a link to the article
 */

export const Results = () => {
  const { results, article, title } = useSelector(selectGame);

  const renderResults = () => {
    return results.map((r, i) => (
      <React.Fragment key={i}>
        <div className="card col-12">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="stickLeft">{r.playerName}</div>
                <div className="stickRight">
                  {r.score} &nbsp; <i className="fas fa-thumbs-up" />
                </div>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="card-space" />
      </React.Fragment>
    ));
  };

  return (
    <div>
      <WikiHowImg title="4" text="How everyone stacks up" />

      <div className="card-space" />
      <div className="card col-12">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              The real answer was{" "}
              <a
                className="link"
                href={article}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}!
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="card-space" />
      {renderResults()}
    </div>
  );
};
