import React from "react";
import "./NavWarning.css";

export const NavWarning = ({ show, unshow, continuePastWarning }) => {
  return show ? (
    <div className="navWarning">
      <p>Are you sure you want to leave this game?</p>
      <button type="button" className="btn btn-primary" onClick={unshow}>
        <h3>No! Take me back to the game</h3>
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={continuePastWarning}
      >
        <h3>Yup, I'm sure</h3>
      </button>
    </div>
  ) : null;
};
