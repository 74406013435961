import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../router";
import "./Title.css";

export const Title = () => {
  // TODO: don't do title click if already at home page
  return (
    <Link className="title-title textOutline" to={ROUTES.root}>
      <p className="title-smallish">
        the{" "}
        <span className="title-epic">
          <span className="title-brown">WIKI</span>
        </span>{" "}
        <span className="title-epic">HOW</span>{" "}
        <span className="title-smallish"> game</span>
      </p>
    </Link>
  );
};
