import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavMenu } from "../../NavMenu";
import {
  clearUnread,
  selectUnreadMessages,
} from "../../store/services/messages";
import { Title } from "../Title";
import { Chat } from "../interface/Chat";
import "./AppMenu.css";
import { HowToPlay } from "./HowToPlay";

// TODO: split render functions out into separate component files

export const AppMenu = () => {
  const dispatch = useDispatch();
  const unreadMessages = useSelector(selectUnreadMessages);
  const [menuOpen, setMenuOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);

  const handleAnyMenuClick = () => {
    setMenuOpen(false);
  };

  const handeMenuButtonClick = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  const handleHowToPlayButtonClick = () => {
    setHowToPlayOpen((howToPlayOpen) => !howToPlayOpen);
  };

  const handleChatButtonClick = () => {
    if (!chatOpen) {
      // read all messages (clear unread)
      dispatch(clearUnread());
    }
    setChatOpen((chatOpen) => !chatOpen);
  };

  function renderMenu() {
    let c = "appMenu-menu " + (menuOpen ? "appMenu-menu-active" : "");
    return (
      <div onClick={handleAnyMenuClick} className={c}>
        <NavMenu />
      </div>
    );
  }

  // TODO: separate this into flex, three pieces, one for menu button, one for title, and one for right buttons
  // right buttons should disappear and go into the menu on mobile, or at least the help should
  function renderHeader() {
    return (
      <React.Fragment>
        <div className="appMenu-bar">
          <div className="container-fluid">
            <div className="row">
              <div className="col appMenu-left">
                <button
                  onClick={handeMenuButtonClick}
                  className="appMenu-menuButton appMenu-button clickable inline"
                >
                  <i className="fas fa-bars" />
                </button>
              </div>
              <div className="col appMenu-center">
                <Title />
              </div>
              <div className="col appMenu-right">
                <button
                  onClick={handleHowToPlayButtonClick}
                  className="appMenu-button clickable inline d-none d-sm-block"
                >
                  <i className="fas fa-info" />
                </button>
                <button
                  onClick={handleChatButtonClick}
                  className="appMenu-button clickable inline"
                >
                  <i className="fas fa-comment fa-flip-horizontal">
                    {unreadMessages > 0 ? (
                      <span className="defaultFont">{unreadMessages}</span>
                    ) : (
                      <span />
                    )}
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="appMenu-bar-shadow" />
      </React.Fragment>
    );
  }

  function renderFooter() {
    return <div className="appMenu-footer">Experimental Footer.</div>;
  }

  return (
    <Fragment>
      {renderHeader()}
      {renderMenu()}
      <Chat open={chatOpen} />
      <HowToPlay open={howToPlayOpen} close={handleHowToPlayButtonClick} />
      {renderFooter()}
    </Fragment>
  );
};
