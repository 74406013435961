import { createSlice } from "@reduxjs/toolkit";
import * as strings from "../../strings";
import { MAX_MESSAGES } from "./messages.const";

const initialState = {
  messages: [{ owner: "client", contents: strings.welcomeMessage }],
  unreadMessages: 0,
};

export const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    addMessage: (state, action) => {
      if (state.messages.length >= MAX_MESSAGES - 1) {
        state.messages.shift();
      }
      state.messages = [...state.messages, action.payload];
      state.unreadMessages = state.unreadMessages + 1;
    },
    clearUnread: (state) => {
      state.unreadMessages = 0;
    },
  },
});

// export selector
export const selectMessages = (state) => state.messages.messages;
export const selectUnreadMessages = (state) => state.messages.unreadMessages;

// Action creators are generated for each case reducer function
export const { setMessages, clearMessages, addMessage, clearUnread } =
  messageSlice.actions;

export default messageSlice.reducer;
