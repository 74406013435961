import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navWarning: false,
  showPasswordInput: false,
  tempPassword: "",
};

export const overlays = createSlice({
  name: "overlays",
  initialState,
  reducers: {
    toggleNavWarning: (state) => {
      state.navWarning = !state.navWarning;
    },
    toggleShowPasswordInput: (state) => {
      state.showPasswordInput = !state.showPasswordInput;
    },
    setTempPassword: (state, action) => {
      state.tempPassword = action.payload;
    },
  },
});

// export selector
export const selectNavWarning = (state) => state.overlays.navWarning;
export const selectShowPasswordInput = (state) =>
  state.overlays.showPasswordInput;
export const selectTempPassword = (state) => state.overlays.tempPassword;

// Action creators are generated for each case reducer function
export const { toggleNavWarning, toggleShowPasswordInput, setTempPassword } =
  overlays.actions;

export default overlays.reducer;
