import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectResults, setImage } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";
import gameOverImageString from "./gameOverImageString";

/**
	STEP 6 ? <- not sure if 6 is right.


 * This page will display the final standings. Specifically declaring the winner.
	It will should also display a button to link back into the same game.
	
 */

export const GameOver = () => {
  const dispatch = useDispatch();
  const { results } = useSelector(selectResults);

  useEffect(() => {
    dispatch(setImage(gameOverImageString));
  }, [dispatch]);

  const getFirstPlace = () => {
    return (results && results[0] && results[0].playerName) || "no one";
  };

  const renderResults = () => {
    return results.map((r, i) => (
      <React.Fragment key={i}>
        <div className="card col-12">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="stickLeft">{r.playerName}</div>
                <div className="stickRight">
                  {r.score} &nbsp; <i className="fas fa-thumbs-up" />
                </div>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className="card-space" />
      </React.Fragment>
    ));
  };

  return (
    <div>
      <WikiHowImg
        title={<i className="fas fa-trophy gameOver-icon" />}
        text={
          <div className="gameOver-title ">
            <span className="gameOver-title-nameHighlight">
              {getFirstPlace()}
            </span>
            &nbsp; has won the game!
          </div>
        }
      />

      <div className="card-space" />
      {renderResults()}

      <div className="card-space" />
      <div className="card col-12">
        <div className="card-body">
          <div className="row">
            <div className="col-12">A new game will be starting shortly.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
