import React, { Component } from "react";
import { Advertisement } from "../Advertisement";
//about.js

export class About extends Component {
  render() {
    return (
      <div>
        <div className="card-space" />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">About The WikiHow Game</h5>
            <p className="card-text">
              The idea of the Wiki How Game came about after a conversation
              beginning with, "Wouldn't it be hilarious if..." But the initial
              incarnation was a very different product. It was originally a
              single page with a very strong retro gameboy aesthetic, complete
              with pallete conversion of the incoming pictures. However, by the
              guidance of playtesters, the game has grown into a much better
              game.
            </p>
            <p className="card-text">
              I've continued development because despite heavy initial
              criticism, people enjoyed the game, and it is very satisfying to
              see a creation enjoyed. A hearty thank you to all my playtesters!
            </p>
          </div>
        </div>
        <div className="card-space" />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Who Did This</h5>
            <p className="card-text">
              The primary developer was{" "}
              <a className="link" href="https://linkedin.com/in/aronhommas">
                Aron
              </a>
              .
              <br />
              In 2024,{" "}
              <a className="link" href="https://github.com/moromis">
                moromis
              </a>{" "}
              rewrote some of the website.
            </p>
            <p className="card-text">
              <a className="link" href="https://alphazeba.itch.io/">
                More fun games
              </a>{" "}
              by Aron.
            </p>
          </div>
        </div>
        <div className="card-space" />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">How To Support</h5>
            <p className="card-text">
              If you would like to contribute to the cause and help keep the
              servers online, you can donate via ko-fi.
            </p>
            <Advertisement />
          </div>
        </div>
        <div className="card-space" />
      </div>
    );
  }
}
