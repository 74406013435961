import openSocket from "socket.io-client";
import {
  ActionConnectionCodes,
  CommentsConnectionCodes,
  ServerConnectionCodes,
  ServerStateConnectionCodes,
  StatewiseServerConnectionCodes,
  TestConnectionCodes,
  gameStateEnum,
} from "./apiEnums";
import {
  setArticle,
  setGuesses,
  setImage,
  setResults,
  setScoredGuesses,
  setTitle,
} from "../store/services/game";
import { setGameState } from "../store/services/gameState";
import { addMessage } from "../store/services/messages";
import { startTimer } from "../store/services/timer";
import { store } from "../store/store";

const config = require("../config.json")[process.env.NODE_ENV];

const baseUrl = config["baseUrl"];
console.log("initializing socket");
const socket = openSocket(baseUrl);

// TODO: fix setState usage -- just send what to change the values to as part of the emit, yeah?

export const unsubscribeAll = () => {
  Object.values(TestConnectionCodes).forEach((code) => {
    socket.off(code);
  });
  Object.values(ServerConnectionCodes).forEach((code) => {
    socket.off(code);
  });
  Object.values(ServerStateConnectionCodes).forEach((code) => {
    socket.off(code);
  });
  Object.values(StatewiseServerConnectionCodes).forEach((code) => {
    socket.off(code);
  });
  Object.values(CommentsConnectionCodes).forEach((code) => {
    socket.off(code);
  });
  Object.values(ActionConnectionCodes).forEach((code) => {
    socket.off(code);
  });
};

export function subscribeToPing(callBack) {
  socket.on(TestConnectionCodes.drip, () => {
    console.log("drip received");
    socket.emit("drop");
    callBack();
  });
}

/**
 * subscribe to different messages the server may send at any time.
 */
export function subscribeToServerMessages(setState) {
  socket.on(ServerConnectionCodes.doneGuessing, () => {
    //TODO
    //move to the waiting screen.
    //because you are now done guessing.
  });
  socket.on(ServerConnectionCodes.doneVoting, () => {
    //TODO combine all 'done' style events to a singular 'done' event interpreted based on which phase the game is in.
    //TODO
    //do whatever needs to be done when done voting.
    //move to the end of voting screen.
  });
  socket.on(ServerConnectionCodes.haveJoinedGame, (content) => {
    console.log(`we have joined the game ${content}`);
    setName;
    store.dispatch(setGameState(gameStateEnum.wait));
    setState((state) => ({
      ...state,
      page: 1, // the game number
    }));
  });
  socket.on(ServerConnectionCodes.joinRejected, (content) => {
    // @kevin 2024/1/31: what do we want to do here?
  });
  socket.on(ServerConnectionCodes.imgUrl, (content) => {
    store.dispatch(setImage(content));
  });
  socket.on(ServerConnectionCodes.title, (content) => {
    store.dispatch(setTitle(content.title));
    store.dispatch(setArticle(content.article));
  });
  socket.on(ServerConnectionCodes.timer, (content) => {
    store.dispatch(startTimer(content));
  });
}

/**
 * subscribe to server messages that result in a state change.
 */
export function subscribeToServerStateMessages() {
  socket.on(ServerStateConnectionCodes.stateGuess, (content) => {
    //needs to also clear all previous data.
    store.dispatch(setGameState(gameStateEnum.guess));
    store.dispatch(setImage(content));
  });
  socket.on(ServerStateConnectionCodes.stateVote, (content) => {
    //shuffle the options before placing them in memory
    //fisher-yates shuffle
    let m = content.length,
      t,
      i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = content[m];
      content[m] = content[i];
      content[i] = t;
    }
    store.dispatch(setGameState(gameStateEnum.vote));
    store.dispatch(setGuesses(content));
  });
  socket.on(ServerStateConnectionCodes.stateScoring, (content) => {
    store.dispatch(setGameState(gameStateEnum.scoring));
    store.dispatch(setScoredGuesses(content.sort((a, b) => b.score - a.score))); //sorted
    // @kevin 2024/2/3: the program was assumedly working fine without clearing guesses... but idk how?
    // this seems to be necessary with redux
    store.dispatch(setGuesses([]));
  });
  socket.on(ServerStateConnectionCodes.stateResults, (content) => {
    store.dispatch(setGameState(gameStateEnum.results));
    store.dispatch(setResults(content.sort((a, b) => b.score - a.score))); //sorted
  });
  socket.on(ServerStateConnectionCodes.stateGameOver, (content) => {
    store.dispatch(setGameState(gameStateEnum.gameOver));
    store.dispatch(setResults(content.sort((a, b) => b.score - a.score))); //sorted
  });
}

export function subscribeToStatewiseServerMessage() {
  // different than other messages because it needs access to the current state.
  socket.on(StatewiseServerConnectionCodes.makeOwner, () => {
    const { gameState } = store.getState();
    if (gameState.state === gameStateEnum.wait) {
      store.dispatch(setGameState(gameStateEnum.waitCreate));
    }
  });
}

export function subscribeToComments() {
  socket.on(CommentsConnectionCodes.message, (content) => {
    store.dispatch(addMessage(content));
  });
  socket.on(ServerConnectionCodes.joinRejected, (content) => {
    store.dispatch(addMessage({ owner: "server", contents: content }));
  });
}

export function sendMessage(message) {
  socket.emit(ActionConnectionCodes.message, message);
}

export function setName(name) {
  socket.emit(ActionConnectionCodes.setName, name);
}

export function sendGuess(guess) {
  socket.emit(ActionConnectionCodes.guess, guess);
}

export function joinGame(code, password) {
  socket.emit(ActionConnectionCodes.joinGame, {
    code: code,
    password: password,
  });
}

export function sendLame() {
  socket.emit(ActionConnectionCodes.lame);
}

export function sendVote(vote) {
  socket.emit(ActionConnectionCodes.vote, vote);
}

export function sendCreate(gameDetails) {
  socket.emit(ActionConnectionCodes.create, gameDetails);
}

export function sendStart() {
  socket.emit(ActionConnectionCodes.start);
}

export function sendLeave() {
  socket.emit(ActionConnectionCodes.leave);
}

export function sendNextPhase() {
  // used for testing to skip the phase .
  socket.emit(ActionConnectionCodes.nextPhase);
}

export function postFeedback(feedback) {
  return fetch(baseUrl + "/api/feedback", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(feedback),
  })
    .then((res) => {
      // need to throw error if response is not 202
      if (res.status === 200) {
        res.text().then((text) =>
          store.dispatch(
            addMessage({
              owner: "server",
              contents: text,
            })
          )
        );
      } else {
        res
          .text()
          .then((text) => console.log("error posting feedback: " + text));
        throw new Error("error posting feedback.");
      }
    })
    .catch(() => {
      // if there's an error tell the user friendly, but then also spit the error message in the console.
      store.dispatch(
        addMessage({
          owner: "client",
          contents:
            "I'm sorry, there was an issue sending your feedback.  Please try again.",
        })
      );
    });
}

export function getPublicGamesAsync() {
  console.log(baseUrl);
  return fetch(baseUrl + "/api/games")
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data.games;
    })
    .catch((e) => {
      console.log(e);
    });
}
