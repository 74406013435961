import React, { useState } from "react";

import { useSelector } from "react-redux";
import { sendVote } from "../../../../io/api";
import { selectGuesses } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";

/**
	STEP 2

    props
    onVote
    

 * will prominently display the image to guess about.
 * will need to have the LAME button.
 * will need to have the guess dialogue.
    guess bar is auto selected.
    enter sends the guess.
    on guess sent, the guess bar is emptied.
    will need to display the timer
    needs the imgUrl

    will also need whatever other information the waiting screen requires.
 */

export const Vote = () => {
  const [votes, setVotes] = useState([]);
  const guesses = useSelector(selectGuesses);

  const handleVote = (vote, index) => {
    // TODO: replace 2 with "ALLOWED_GUESSES" constant
    if (votes.length >= 2) {
      return;
    }

    sendVote(vote);
    setVotes([...votes, index]);
  };

  const renderVoteButtons = () => {
    return (
      guesses &&
      guesses.map &&
      guesses.map((g, i) => {
        let ani = "";
        let c = "fas fa-thumbs-up vote";

        switch (votes.filter((v) => v === i).length) {
          case 1:
            c += " vote-chosen textOutline";
            ani = "vote-animation-bounce";
            break;

          case 2:
            c += " vote-veryChosen textOutline";
            ani = "vote-animation-wiggle";
            break;

          default:
            break;
        }

        return (
          <li
            key={i}
            onClick={() => handleVote(g, i)}
            className="list-group-item clickable card-clickable"
          >
            <div className="row">
              <div className="col-12">
                <div className="containLeft">{g}</div>
                <div className="stickRight">
                  <div className={ani}>
                    <i className={c} />
                  </div>
                </div>
                &nbsp;{" "}
                {/* this provides the lineheight for an otherwise aparently empty column */}
              </div>
            </div>
          </li>
        );
      })
    );
  };

  return (
    <div>
      <WikiHowImg title="2" text="Choose your favorite" />
      <div className="card-space" />
      <div className="col-12">{2 - votes.length} remaining votes</div>
      <div className="card col-12">
        <ul className="list-group list-group-flush">{renderVoteButtons()}</ul>
      </div>
    </div>
  );
};
