import React, { Component } from "react";
//AppContent.js
import "./AppContent.css";

export class AppContent extends Component {
  render() {
    return (
      <div className="appContent-main">
        <div ref={(x) => (this._content = x)} className="appContent-scrollWrap">
          <div className="appContent-header" />
          <div className="container appContent-widthHandler">
            <div className="row">
              <div className="col-12">{this.props.children}</div>
            </div>
          </div>
          <div className="appContent-footer" />
        </div>
      </div>
    );
  }
}
