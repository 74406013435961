import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendGuess, sendLame } from "../../../../io/api";
import { addGuess, selectGuesses } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";

/**
	STEP 1

    props
    imgSrc  string url
    onLame  fn
    onGuess fn(string)
    

 * will prominently display the image to guess about.
 * will need to have the guess dialogue.
    guess bar is auto selected.
    enter sends the guess.
    on guess sent, the guess bar is emptied.
    will need to display the timer
    needs the imgUrl

    will also need whatever other information the waiting screen requires.
 */

export const Guess = () => {
  const dispatch = useDispatch();
  const guesses = useSelector(selectGuesses);
  const guessInput = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (guessInput.current) {
      let guess = "How to " + guessInput.current.value;
      if (guess && guess.length > 0) {
        sendGuess(guess);
      }
      guessInput.current.value = "";
      dispatch(addGuess(guess));
    }
  };

  const handleLame = (e) => {
    e.preventDefault();
    sendLame();
  };

  const renderPreviousGuesses = () => {
    return guesses.map((g, i) => (
      <li key={i} className="list-group-item">
        {g}
      </li>
    ));
  };

  const renderGuessForm = () => {
    // TODO: replace 2 with "ALLOWED_GUESSES" constant
    if (guesses.length < 2) {
      return (
        <li className="list-group-item">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">How to:</span>
              </div>
              <input
                ref={(input) => (guessInput.current = input)}
                className="form-control"
              />
            </div>
            <button className="btn btn-success">Guess</button>
          </form>
        </li>
      );
    }
  };

  return (
    <div>
      <WikiHowImg
        title="1" // TODO: what is this for?
        text="give your best guess as to what this is"
      />
      <div className="card-space" />
      <div className="col-12">{2 - guesses.length} remaining guesses</div>
      <div className="card col-12">
        <ul className="list-group list-group-flush">
          {renderPreviousGuesses()}
          {renderGuessForm()}
        </ul>
      </div>
      <button onClick={handleLame} className="btn btn-danger">
        Lame
      </button>
    </div>
  );
};
