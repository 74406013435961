import { configureStore } from "@reduxjs/toolkit";
import game from "./services/game";
import gameState from "./services/gameState";
import messages from "./services/messages";
import nickname from "./services/nickname";
import overlays from "./services/overlays";
import publicGamesList from "./services/publicGamesList";
import timer from "./services/timer";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    nickname,
    gameState,
    game,
    timer,
    messages,
    publicGamesList,
    overlays,
  },
});
