import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";

import { AppContent } from "./components/AppContent";
import { AppMenu } from "./components/AppMenu/AppMenu";

import { Timer } from "./components/Timer";

import {
  // sendNextPhase,
  joinGame,
  sendLeave,
  subscribeToComments,
  subscribeToPing,
  subscribeToServerMessages,
  subscribeToServerStateMessages,
  subscribeToStatewiseServerMessage,
  unsubscribeAll,
} from "./io/api";

import { Outlet, useLocation } from "react-router-dom";
import { NavWarning } from "./NavWarning";
import { gameStateEnum } from "./io/apiEnums";
import { ROUTES } from "./router";
import { setGameState } from "./store/services/gameState";
import {
  selectShowPasswordInput,
  selectTempPassword,
  setTempPassword,
  toggleShowPasswordInput,
} from "./store/services/overlays";

export const App = () => {
  // change to ref
  let _passwordInput = undefined;

  const location = useLocation();
  const dispatch = useDispatch();
  const showPasswordInput = useSelector(selectShowPasswordInput);
  const tempPassword = useSelector(selectTempPassword);

  const [state, setState] = useState({
    subscribed: false,
  });

  useEffect(() => {
    if (!state.subscribed) {
      subscribeToPing();
      subscribeToServerMessages(setState);
      subscribeToServerStateMessages();
      subscribeToStatewiseServerMessage();
      subscribeToComments();

      setState((state) => ({ ...state, subscribed: true }));
    }

    return () => {
      unsubscribeAll();
      setState((state) => ({ ...state, subscribed: false }));
    };
  }, [unsubscribeAll]); // TODO: after adding linting add all dependencies

  const handleCancelPasswordInput = () => {
    dispatch(toggleShowPasswordInput());
    dispatch(setTempPassword(""));
  };

  // TODO: why are neither of the params used in this method?
  const handleJoinWithPassword = (game, password) => {
    joinGame(tempPassword.code, _passwordInput.value);
    dispatch(toggleShowPasswordInput());
    dispatch(setTempPassword(""));
  };

  const handleLeave = () => {
    sendLeave();
    dispatch(setGameState(gameStateEnum.none));
  };

  const renderPasswordInput = () => {
    let contents = "password input";
    if (showPasswordInput) {
      contents = (
        <div className="card-body">
          <form onSubmit={handleJoinWithPassword}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Password:</span>
              </div>
              <input
                className="form-control"
                ref={(x) => (_passwordInput = x)}
              />
            </div>
            <div className="container">
              <div className="row">
                <div className="col center">
                  <button className="btn btn-success">Submit</button>
                </div>
                <div className="col center">
                  <button
                    onClick={handleCancelPasswordInput}
                    type="button"
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }

    return (
      <div
        className={
          "passwordInput" + (showPasswordInput ? "  passwordInput-active" : "")
        }
        onClick={handleCancelPasswordInput}
      >
        <div className="card" onClick={(e) => e.stopPropagation()}>
          {contents}
        </div>
      </div>
    );
  };

  const renderTimer = () => {
    if (location.pathname === ROUTES.root + ROUTES.game) {
      return <Timer />;
    }
  };

  return (
    <div className="app-main">
      {renderPasswordInput()}
      <AppMenu />
      <NavWarning
        show={state.navWarning}
        continuePastWarning={() => {
          handleLeave();
          setState((state) => ({ ...state, navWarning: false }));
        }}
        unshow={() => setState((state) => ({ ...state, navWarning: false }))}
      />
      <AppContent>
        <Outlet />
      </AppContent>
      {renderTimer()}
    </div>
  );
};
