import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  games: [],
};

export const publicGamesList = createSlice({
  name: "publicGamesList",
  initialState,
  reducers: {
    setPublicGamesList: (state, action) => {
      state.games = action.payload;
    },
  },
});

// export selector
export const selectPublicGamesList = (state) => state.publicGamesList.games;

// Action creators are generated for each case reducer function
export const { setPublicGamesList } = publicGamesList.actions;

export default publicGamesList.reducer;
