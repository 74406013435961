import { createBrowserRouter } from "react-router-dom";
import { App } from "./App";
import { HowToPlay } from "./components/AppMenu/HowToPlay";
import { About } from "./components/pages/About";
import { Create } from "./components/pages/Create";
import { Feedback } from "./components/pages/Feedback";
import { FeedbackThankYou } from "./components/pages/FeedbackThankYou";
import { Game } from "./components/pages/Game/Game";
import { Home } from "./components/pages/Home";
import { Join } from "./components/pages/Join";
import ErrorPage from "./errorPage";

export const ROUTES = {
  root: "/",
  create: "create",
  game: "game",
  join: "join",
  thanks: "thanks",
  about: "about",
  feedback: "feedback",
  howToPlay: "howtoplay",
  leaveToHome: "home",
};

export const router = createBrowserRouter([
  {
    path: ROUTES.root,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: ROUTES.create,
        element: <Create />,
      },
      {
        path: ROUTES.game,
        element: <Game />,
      },
      {
        path: ROUTES.join,
        element: <Join />,
      },
      {
        path: ROUTES.about,
        element: <About />,
      },
      // {
      //   path: ROUTES.feedback,
      //   element: <Feedback />,
      // },
      {
        path: ROUTES.thanks,
        element: <FeedbackThankYou />,
      },
      {
        path: ROUTES.howToPlay,
        element: <HowToPlay open />,
      },
      {
        path: ROUTES.leaveToHome,
        element: <Home />,
      }
    ],
  },
]);
