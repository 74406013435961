import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendStart } from "../../../../io/api";
import { setImage } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";

/**
 * props
	creator
	onStart
 */
export const Wait = (props) => {
  const dispatch = useDispatch();

  const img =
    "https://www.wikihow.com/images/thumb/8/8e/Learn-to-Wait-for-What-You-Want-Step-6-Version-2.jpg/aid67738-v4-728px-Learn-to-Wait-for-What-You-Want-Step-6-Version-2.jpg";

  useEffect(() => {
    dispatch(setImage(img));
  }, [dispatch, img]);

  const handleStart = () => {
    if (props.canStart) {
      sendStart();
    }
  };

  const renderStartButton = () => {
    if (props.creator) {
      return (
        <div className="card col-12">
          <h5 className="card-title">Start the game when you are ready</h5>
          <p className="card-text">Hey, you created a game!</p>
          <p className="card-text">
            When you want the game to start, press the button below to begin the
            game! After a period of time, the game will start automatically.
          </p>
          <div className="card-text">
            <button className="btn btn-success" onClick={handleStart}>
              Start
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <WikiHowImg title="0" text="Waiting for the game to be started" />
      <div className="card-space" />
      {renderStartButton()}
    </div>
  );
};
