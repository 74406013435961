import { useSelector } from "react-redux";
import { gameStateEnum } from "../../../io/apiEnums";
import { selectGameState } from "../../../store/services/gameState";
import { Join } from "../Join";
import { GameOver, Guess, Results, Scoring, Vote, Wait } from "./stages";

export const Game = () => {
  const gameState = useSelector(selectGameState);

  const renderGame = () => {
    let gameComponent = null;

    switch (gameState) {
      case gameStateEnum.none:
        gameComponent = <Join />;
        break;
      case gameStateEnum.guess:
        gameComponent = <Guess />;
        break;
      case gameStateEnum.vote:
        gameComponent = <Vote />;
        break;
      case gameStateEnum.scoring:
        gameComponent = <Scoring />;
        break;
      case gameStateEnum.results:
        gameComponent = <Results />;
        break;
      case gameStateEnum.waitCreate:
        gameComponent = <Wait creator={true} canStart={true} />;
        break;
      case gameStateEnum.gameOver:
        gameComponent = <GameOver />;
        break;
      case gameStateEnum.wait:
        break;
      default: // TODO the default should be wait, there should be an error page. (the error page should have a 'try to join' button, that reattempts joining properly and a 'refresh' button that reconnects to the server.
        gameComponent = <Wait creator={false} canStart={false} />;
        break;
    }
    return <>{gameComponent}</>;
  };

  return renderGame();
};
