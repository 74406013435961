import { createSlice } from "@reduxjs/toolkit";
import { gameStateEnum } from "../../io/apiEnums";

const initialState = {
  state: gameStateEnum.none, //the game's state.
};

export const gameSlice = createSlice({
  name: "gameState",
  initialState,
  reducers: {
    setGameState: (state, action) => {
      state.state = action.payload;
    },
  },
});

// export selector
export const selectGameState = (state) => state.gameState.state;

// Action creators are generated for each case reducer function
export const { setGameState } = gameSlice.actions;

export default gameSlice.reducer;
