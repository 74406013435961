import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../router";

// Feedback
// will provide a form to provide information to me.

export class FeedbackThankYou extends Component {
  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Thank You!</h3>
            <p>Thanks for the feedback!</p>
            <Link to={ROUTES.root}>Return home</Link>
          </div>
        </div>
      </div>
    );
  }
}
