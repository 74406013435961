/**
 * an enum that defines the gameState values.
 */
export const gameStateEnum = {
  none: -2,
  wait: -1, //before the game begins.
  waitCreate: 0,
  guess: 1, //players are shown the picture and guess the article title.
  vote: 2, //players are shown the other players guesses and the actual title. vote for their favorite.
  scoring: 3, //the actual title is revealed.  everyone who voted for the actual title gets a point.
  //everyone whose guess was voted for gets a point.
  results: 4, //people's current standings are shown briefly.
  gameOver: 5,
};

// TODO: have server and client share codes so that they're not hard-coded strings/
// so you don't have to change them in both locations (single source of truth)
export const TestConnectionCodes = {
  drip: "drip",
};

export const ServerConnectionCodes = {
  doneGuessing: "doneGuessing",
  doneVoting: "doneVoting",
  haveJoinedGame: "haveJoinedGame",
  joinRejected: "joinRejected",
  imgUrl: "imgUrl",
  title: "title",
  timer: "timer",
};

export const ServerStateConnectionCodes = {
  stateGuess: "stateGuess",
  stateVote: "stateVote",
  stateScoring: "stateScoring",
  stateResults: "stateResults",
  stateGameOver: "stateGameOver",
};

export const StatewiseServerConnectionCodes = {
  makeOwner: "makeOwner",
};

export const CommentsConnectionCodes = {
  message: "message",
};

export const ActionConnectionCodes = {
  message: "message",
  setName: "setName",
  guess: "guess",
  joinGame: "joinGame",
  lame: "lame",
  vote: "vote",
  create: "create",
  start: "start",
  leave: "leave",
  nextPhase: "nextPhase",
};
