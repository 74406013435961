import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../io/api";
import { clearUnread, selectMessages } from "../../store/services/messages";
import "./Chat.css";

export const Chat = ({ open }) => {
  const dispatch = useDispatch();
  const messages = useSelector(selectMessages);
  const messagesEnd = useRef(null);
  const chatBox = useRef(null);

  const [atBottom, setAtBottom] = useState(true);

  const handleSendMessage = (e) => {
    e.preventDefault();
    let message = chatBox.current?.value;
    chatBox.current.value = "";
    sendMessage(message);
  };

  const handleReadMessages = () => {
    dispatch(clearUnread());
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const scrollToBottom = () => {
    messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const renderMessage = (message, key) => {
    return (
      <React.Fragment key={key}>
        <div className="card end-space">
          <div className="chat-message-body">
            <div className="chat-message-header">
              <i className="fas fa-user-circle" /> {message.owner}
            </div>
            <div className="chat-message-contents">{message.contents}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  let c = "chat-chat " + (open ? "chat-chat-active" : undefined);
  let seeNewButtonC =
    "btn btn-primary chat-chatFeed-seeNewButton " +
    (!atBottom ? "chat-chatFeed-seeNewButton-active" : "");

  return (
    <div className={c}>
      <div
        className="chat-messages"
        onScroll={handleScroll}
        onMouseEnter={handleReadMessages}
      >
        {/* <div className="chat-chatFeed-topSpace" /> */}
        {messages ? messages.map((m, i) => renderMessage(m, i)) : null}
        {!atBottom ? (
          <button className={seeNewButtonC} onClick={scrollToBottom}>
            <i className="fas fa-caret-down" />
            &nbsp;newest messages&nbsp;
            <i className="fas fa-caret-down" />
          </button>
        ) : null}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            messagesEnd.current = el;
          }}
        ></div>
      </div>
      <div className="chat-chatInput">
        <form className="chat-form" onSubmit={handleSendMessage}>
          <div className="chat-chatInputBox">
            <input
              className="chat-newMessageInput"
              ref={(x) => (chatBox.current = x)}
            />
          </div>
          <button className="btn btn-primary chat-sendChatButton">
            <i className="fas fa-paper-plane" />
          </button>
        </form>
      </div>
    </div>
  );
};
