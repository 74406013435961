import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getPublicGamesAsync, joinGame } from "../../io/api";
import { ROUTES } from "../../router";
import {
  setTempPassword,
  toggleShowPasswordInput,
} from "../../store/services/overlays";
import {
  selectPublicGamesList,
  setPublicGamesList,
} from "../../store/services/publicGamesList";

export const Join = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _gameCode = useRef(undefined);
  const publicGamesList = useSelector(selectPublicGamesList);

  const handleGetPublicGames = useCallback(() => {
    getPublicGamesAsync().then((games) => {
      dispatch(setPublicGamesList(games));
    });
  }, [publicGamesList]);

  // all initial effects that don't have side-effects (i.e. nothing
  // needs to be re-run in the future after first render)
  useEffect(() => {
    handleGetPublicGames();
  }, []); // no dependency list

  const handleAskForPassword = (game) => {
    dispatch(toggleShowPasswordInput());
    dispatch(setTempPassword(game));
  };

  const handleJoinGame = (game) => {
    // TODO: should check that you aren't already in a game.
    // so you would have to leave a game before you can join a game.
    if (game.password) {
      // TODO we will need to ask the user for a password.
      // TODO build get password popup.
      // because of the password, we will need to wait for a password accepted response to get into the game.
      handleAskForPassword(game);
    } else {
      // @kevin 2024/2/3: I removed a game.code block here as the code was always unset
      // TODO: figure out what to do with the code if we had it and how we set it?
      console.error("no game code provided");
      navigate(ROUTES.root + ROUTES.game);
      joinGame(game.code, null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (_gameCode.current) {
      // TODO maybe query the server for this specific gameCode.
      // right now we will be assuming that the game requires a password.
      // since the game is private.
      handleJoinGame({
        code: _gameCode.current.value,
        password: true,
      });
    }
  };

  const renderPublicGames = () => {
    if (publicGamesList && publicGamesList.length < 1) {
      return (
        <li key={"nogames"} className="list-group-item clickable">
          <div className="row">
            <div className="col-12">
              looks like there aren't any games. Go{" "}
              <Link to={ROUTES.root + ROUTES.create}>here</Link> to make one.
            </div>
          </div>
        </li>
      );
    } // <i class='fas fa-unlock'></i>

    return publicGamesList
      ? publicGamesList.map((g) => (
          <li
            key={g.code}
            onClick={() => handleJoinGame(g)}
            className="list-group-item clickable"
          >
            <div className="row">
              <div className="col-2 col-md-1 join-iconBadge">
                {g.password ? <i class="fas fa-lock"></i> : ""}
                &nbsp;
                <span>
                  {g.numPlayers}
                  <i className="fas fa-user-circle"></i>
                </span>
              </div>
              <div className="col-8 col-md-9">{g.code}</div>
              <div className="col-2">
                <button className="btn btn-success btn-block">Join</button>
              </div>
            </div>
          </li>
        ))
      : null;
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Join a game by name</h5>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                ref={(x) => (_gameCode.current = x)}
                className="form-control"
              />
            </div>
            <button className="btn btn-success">Join</button>
          </form>
        </div>
      </div>
      <div className="card-space" />
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Join a public game</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {/* TODO: after we click this, render a spinner so the user knows we are doing something
                      and then remove it after we've handled the refresh
            */}
            <button className="btn btn-primary" onClick={handleGetPublicGames}>
              Refresh
            </button>
          </li>
          {renderPublicGames()}
        </ul>
      </div>
      <div className="card-space" />
    </div>
  );
};
