import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  img: "", //the img url.
  article: "", // the article url.
  title: "", //title of the wiki image.
  guesses: [],
  scoredGuesses: [],
  results: [],
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setImage: (state, action) => {
      state.img = action.payload;
    },
    setArticle: (state, action) => {
      state.article = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    addGuess: (state, action) => {
      state.guesses = [...state.guesses, action.payload];
    },
    setGuesses: (state, action) => {
      state.guesses = action.payload;
    },
    setScoredGuesses: (state, action) => {
      state.scoredGuesses = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

// export selector
export const selectImage = (state) => state.game.img;
export const selectTitle = (state) => state.game.title;
export const selectArticle = (state) => state.game.article;
export const selectGuesses = (state) => state.game.guesses;
export const selectScoredGuesses = (state) => state.game.scoredGuesses;
export const selectResults = (state) => state.game.results;
export const selectGame = (state) => state.game;

// Action creators are generated for each case reducer function
export const {
  setImage,
  setTitle,
  setArticle,
  addGuess,
  setGuesses,
  setScoredGuesses,
  setResults,
} = gameSlice.actions;

export default gameSlice.reducer;
