import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../router";
import "./HowToPlay.css";

const Instruction = ({ children }) => (
  <div className="card howToPlay-instruction">
    <div className="card-body p-2">{children}</div>
  </div>
);

export const HowToPlay = ({ open, close }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = window.innerWidth < 575.98;

  useEffect(() => {
    if (
      !isSmallScreen &&
      location.pathname === ROUTES.root + ROUTES.howToPlay
    ) {
      navigate(ROUTES.root);
    }
  }, [isSmallScreen, location]);

  return open ? (
    <div className="howToPlay">
      <div className="card howToPlay-card">
        <div className="card-body howToPlay-content">
          {!isSmallScreen ? (
            <button className="howToPlay-closeButton clickable" onClick={close}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="howToPlay-closeButton-icon"
              />
            </button>
          ) : null}
          <h3 className="card-title">How To Play</h3>
          <p className="card-text">
            The Wiki How game is split into 4 main phases.
          </p>
          <div className="container">
            <div className="row end-space">
              <div className="col-lg-6 col-sm-12">
                <Instruction>
                  <h3>1. Guess</h3>
                  <div>
                    Take a good look at the provided picture. Imagine what the
                    title of the article might be...
                  </div>
                  <br />
                  <div>
                    Now, write up to two fake article titles. Try to be
                    accurate, funny, weird, or misleading. Whatever you think
                    might get people to vote for your answers.
                  </div>
                </Instruction>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Instruction>
                  <h3>2. Vote</h3>
                  <div>
                    You will be provided a list of the other player's guesses
                    with the actual title slipped in. You will not be able to
                    see your own guesses on this screen, don't worry!
                  </div>
                  <br />
                  <div>
                    You get <b>2 votes</b>. You don't need to spend them both,
                    but you can vote for the same thing twice!
                  </div>
                </Instruction>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Instruction>
                  <h3>3. Scoring</h3>
                  <div>
                    On the scoring screen, the guesses will have their owners
                    revealed and the number of votes each received.
                  </div>
                  <br />
                  <div>
                    Vote for the real title? <b>1 point</b>.
                    <br />
                    Vote for someone else's title? <b>They get a point</b>.
                  </div>
                </Instruction>
              </div>
              <div className="col-lg-6 col-sm-12">
                <Instruction>
                  <h3>4. Results</h3>
                  <div>
                    You get to see how many points each player has and a link to
                    the wikiHow article will be provided.
                  </div>
                  <br />
                  <div>
                    Once a player has enough points (based on game settings),{" "}
                    <b>they're the winner!</b>
                  </div>
                </Instruction>
              </div>
            </div>

            <div className="row">
              <div className="card-text end-space col">
                <div>
                  <h3>What if we tie?</h3>
                  <p>
                    In the event multiple players break the score threshold on
                    the same round, the player with more points will win. If
                    multiple players are tied for most points, the winner is
                    chosen at random.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
