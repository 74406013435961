import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  time: 0,
  timerIsRunning: false,
};

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setTimer: (state, action) => {
      state.time = action.payload;
    },
    decrementTimer: (state) => {
      state.time = state.time - 1;
    },
    _startTimer: (state) => {
      state.timerIsRunning = true;
    },
  },
});

// start timer
export const startTimer = (newTime) => (dispatch, getState) => {
  dispatch(setTimer(newTime));
  if (!getState().timer.timerIsRunning) {
    dispatch(timerSlice.actions._startTimer());
    setInterval(() => {
      dispatch(decrementTimer());
    }, 1000);
  }
};

// export selector
export const selectTimer = (state) => state.timer.time;

// Action creators are generated for each case reducer function
export const { setTimer, decrementTimer } = timerSlice.actions;

export default timerSlice.reducer;
