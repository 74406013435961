import React from "react";
import { useSelector } from "react-redux";
import { selectGame } from "../../../../store/services/game";
import { WikiHowImg } from "../../../WikiHowImg";

/**
	STEP 4


 * This will show the votes and how many points they made along with who made the guess
    will display if the vote were the real one as well.
	
	this should continue showing the link to the real article.
 */

export const Scoring = () => {
  const { article, title, scoredGuesses } = useSelector(selectGame);

  const renderScores = () => {
    return scoredGuesses.map((g, i) => (
      <React.Fragment key={i}>
        <div className="card col-12">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="stickLeft">
                  {g.score} &nbsp; <i className="fas fa-thumbs-up" />
                </div>
                <div className="containRight scoring-guess">
                  {g.guess}
                  <div className="scoring-title">
                    <i className="fas fa-user-circle" /> &nbsp; {g.playerName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-space" />
      </React.Fragment>
    ));
  };

  return (
    <div>
      <WikiHowImg title="3" text="How'd it go?" />

      <div className="card-space" />
      <div className="card col-12">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              The real answer was{" "}
              <a
                className="link"
                href={article}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}!
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="card-space" />
      {renderScores()}
    </div>
  );
};
