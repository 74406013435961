import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import "./NavMenu.css";
import { gameStateEnum } from "./io/apiEnums";
import { ROUTES } from "./router";
import { selectGameState } from "./store/services/gameState";

const Link = ({ route, faIcon, text, className, onClick=null }) => {
  return (
    <NavLink className={`menu-btn ${className || ""}`} to={route} onClick={onClick}>
      <i className={`fas fa-${faIcon}`} /> {text}
    </NavLink>
  );
};

export const NavMenu = () => {
  const gameState = useSelector(selectGameState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLeave = () => {
      alert("handling laev");
      sendLeave();
      dispatch(setGameState(gameStateEnum.none));
      navigate(ROUTES.root);
  };

  return (
    <nav className="nav">
      <div className="card-space" />
      <Link route={ROUTES.root} faIcon="home" text="home" />
      <Link
        route={gameState !== gameStateEnum.none ? ROUTES.game : ROUTES.join}
        faIcon="gamepad"
        text={gameState !== gameStateEnum.none ? "game" : "join"}
      />
      {gameState !== gameStateEnum.none ?
        <Link
          route={ROUTES.leaveToHome}
          onClick={handleLeave}
          faIcon={"door-open"}
          text={"leave"}
        /> :
        <Link
          route={ROUTES.create}
          faIcon={"plus"}
          text={"create"}
        />
      }
      <Link route={ROUTES.about} faIcon="question" text="about" />
      <Link
        route={ROUTES.howToPlay}
        faIcon="info"
        text="how to play"
        className=".d-none .d-sm-block .d-md-none"
      />
    </nav>
  );
};
