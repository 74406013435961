import React from "react";
import { useSelector } from "react-redux";
import { selectImage } from "../store/services/game";
/**
 * props
 * 	title
 *  text
 */
export const WikiHowImg = ({ title, text }) => {
  const imgSrc = useSelector(selectImage);

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="card">
      <img
        onContextMenu={preventContextMenu}
        className="card-img-top imgHider fullWidth"
        src={imgSrc}
        alt="no cheating"
      />
      <div className="card-body">
        <div className="card-num">{title}</div>
        <span className="card-text">{text}</span>
      </div>
    </div>
  );
};
